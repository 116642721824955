// Generated by Framer (1cc6588)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, getFonts, RichText, useActiveVariantCallback, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import Caret from "https://framerusercontent.com/modules/Axpol70OdRugqukuVIqY/fRCYwLdA8hEctMwccGMQ/IGFqizJ7S.js";
const CaretFonts = getFonts(Caret);

const cycleOrder = ["WmSJxpLun", "grTcacTyg"];

const serializationHash = "framer-ZmSy0"

const variantClassNames = {grTcacTyg: "framer-v-j4ouhq", WmSJxpLun: "framer-v-ucqq7f"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transitions = {default: {damping: 40, delay: 0, mass: 1, stiffness: 600, type: "spring"}};

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const humanReadableVariantMap = {Closed: "WmSJxpLun", Open: "grTcacTyg"}

const getProps = ({height, hover, id, title, width, ...props}) => { return {...props, DApvjrdfG: title ?? props.DApvjrdfG ?? "Product", PWSlWeqS6: hover ?? props.PWSlWeqS6, variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "WmSJxpLun"} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap;hover?: any;title?: string; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale } = useLocaleInfo()

const {style, className, layoutId, variant, PWSlWeqS6, DApvjrdfG, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "WmSJxpLun", transitions, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const { activeVariantCallback, delay } = useActiveVariantCallback(baseVariant)

const onMouseEnter1nzhzpa = activeVariantCallback(async (...args) => {
if (PWSlWeqS6) {
const res = await PWSlWeqS6(...args);
if (res === false) return false;
}
})

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-ZmSy0", ...sharedStyleClassNames, classNames)} style={{display: "contents"}}>
<Transition value={transition}><motion.div {...restProps} className={cx("framer-ucqq7f", className)} data-framer-name={"Closed"} data-highlight layoutDependency={layoutDependency} layoutId={"WmSJxpLun"} onMouseEnter={onMouseEnter1nzhzpa} ref={ref ?? ref1} style={{backgroundColor: "rgb(0, 0, 0)", ...style}} {...addPropertyOverrides({grTcacTyg: {"data-framer-name": "Open"}}, baseVariant, gestureVariant)}><RichText __fromCanvasComponent children={<React.Fragment><motion.p style={{"--font-selector": "SW50ZXItTWVkaXVt", "--framer-font-family": "\"Inter-Medium\", \"Inter\", \"Inter Placeholder\", sans-serif", "--framer-font-size": "15px", "--framer-font-weight": "500", "--framer-letter-spacing": "-0.01em", "--framer-line-height": "2em", "--framer-text-alignment": "left", "--framer-text-color": "var(--extracted-r6o4lv, rgb(255, 255, 255))"}}>Product</motion.p></React.Fragment>} className={"framer-dzcdb1"} fonts={["Inter-Medium"]} layoutDependency={layoutDependency} layoutId={"TmvByBS2c"} style={{"--extracted-r6o4lv": "rgb(255, 255, 255)", "--framer-paragraph-spacing": "0px", opacity: 1}} text={DApvjrdfG} variants={{grTcacTyg: {opacity: 0.5}}} verticalAlignment={"top"} withExternalLayout/><motion.div className={"framer-vj3vph-container"} layoutDependency={layoutDependency} layoutId={"PllKRrRui-container"}><Caret height={"100%"} id={"PllKRrRui"} layoutId={"PllKRrRui"} variant={"OVKxmcYcs"} width={"100%"} {...addPropertyOverrides({grTcacTyg: {variant: "W1rfza3wq"}}, baseVariant, gestureVariant)}/></motion.div></motion.div></Transition>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-ZmSy0 [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-ZmSy0 .framer-1hzu3sp { display: block; }", ".framer-ZmSy0 .framer-ucqq7f { align-content: center; align-items: center; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 5px; height: min-content; justify-content: center; overflow: hidden; padding: 5px 0px 5px 0px; position: relative; width: min-content; }", ".framer-ZmSy0 .framer-dzcdb1 { -webkit-user-select: none; flex: none; height: auto; position: relative; user-select: none; white-space: pre; width: auto; }", ".framer-ZmSy0 .framer-vj3vph-container { flex: none; height: auto; position: relative; width: auto; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-ZmSy0 .framer-ucqq7f { gap: 0px; } .framer-ZmSy0 .framer-ucqq7f > * { margin: 0px; margin-left: calc(5px / 2); margin-right: calc(5px / 2); } .framer-ZmSy0 .framer-ucqq7f > :first-child { margin-left: 0px; } .framer-ZmSy0 .framer-ucqq7f > :last-child { margin-right: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 40
 * @framerIntrinsicWidth 74
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["auto","auto"]},"grTcacTyg":{"layout":["auto","auto"]}}}
 * @framerVariables {"PWSlWeqS6":"hover","DApvjrdfG":"title"}
 * @framerImmutableVariables true
 */
const FramerPuPWcS4HY: React.ComponentType<Props> = withCSS(Component, css, "framer-ZmSy0") as typeof Component;
export default FramerPuPWcS4HY;

FramerPuPWcS4HY.displayName = "Trigger Copy";

FramerPuPWcS4HY.defaultProps = {height: 40, width: 74};

addPropertyControls(FramerPuPWcS4HY, {variant: {options: ["WmSJxpLun", "grTcacTyg"], optionTitles: ["Closed", "Open"], title: "Variant", type: ControlType.Enum}, PWSlWeqS6: {title: "Hover", type: ControlType.EventHandler}, DApvjrdfG: {defaultValue: "Product", displayTextArea: false, title: "Title", type: ControlType.String}} as any)

addFonts(FramerPuPWcS4HY, [...CaretFonts])